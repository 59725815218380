<template>
  <el-dialog
    class="el-dialog-order"
    :width="isMobileScreen?'90%' :'80%'"
    title="Link to Term Contract"
    :visible="dialogLinkTermContract"
    @close="handleLinkTermContractDialogClose"
    @open="handleLinkTermContractDialogOpen"
  >
    <div>
      <table-term-contract v-if="!isTermContractTableLoading" v-loading="isTermContractTableLoading" :isLinkedSalesTermContract="isLinkedSalesTermContract" :linkedSalesTermContract="linkedSalesTermContract" :linkedPurchaseTermContract="linkedPurchaseTermContract"
        hasRadio :isTermContractTableLoading="isTermContractTableLoading" :orderTableData="linkOrderTableData" @handleTermContractRadioSelectChange="handleTermContractRadioSelectChange" :termContractPagination="termContractPagination"
      />
    </div>
    <div class="el-dialog__footer">
      <el-button outline round  @click="handleLinkTermContractDialogClose">
        Cancel
      </el-button>
      <el-button round  type="primary" @click="handleLinkTermContractConfirm" :disabled="!linkedOrderSelected">
        Confirm
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import TableTermContract from './TableTermContract.vue'
export default {
  name: 'DialogLinkTermContract',
  components: {
    TableTermContract
  },
  props: {
    buyerSysOrganizationId: String,
    sellerSysOrganizationId: String,
    dialogLinkTermContract: Boolean,
    isLinkedSalesTermContract: Boolean,
    linkedSalesTermContract: Object,
    linkedPurchaseTermContract: Object,
    orderType: String,
    propsView: String
  },
  data () {
    const termContractPagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    return {
      isTermContractTableLoading: true,
      linkedOrderSelected: null,
      termContractPagination
    }
  },
  computed: {
    ...mapState([
      'isMobileScreen',
    ]),
    myCompanyId () {
      return this.$store.state.currentCompany.id
    }
  },
  methods: {
    handleLinkTermContractDialogOpen () {
      this.getTermOrders(50, 0)
      if (!this.linkedSalesTermContract) {
        this.linkedOrderSelected = null
      }
    },
    handleTermContractPageChange (pageNumber) {
      this.getTermContracts(this.orderPagination.pageSize, pageNumber - 1)
    },
    handleTermContractPageSizeChange (pageSize) {
      this.getTermContracts(pageSize, 0)
    },
    handleTermContractRadioSelectChange (row) {
      this.linkedOrderSelected = row
    },
    handleLinkTermContractDialogClose () {
      this.$emit('handleLinkTermContractDialogClose')
    },
    handleLinkTermContractConfirm () {
      this.$emit('handleLinkTermContractConfirm', this.linkedOrderSelected)
    },
    getTermOrders (pageSize, pageNumber) {
      this.isTermContractTableLoading = true
      this.$request.get({
        url: this.getTermOrderUrl(pageSize, pageNumber)
      }).then(res => {
        if (res?.code === 1000) {
          this.linkOrderTableData = res.data.content
          this.termContractPagination.total = res.data.totalElements
          this.termContractPagination.pageNumber = res.data.pageable.pageNumber + 1
          this.isTermContractTableLoading = false
        }
      })
    },
    getTermOrderUrl (pageSize, pageNumber) {
      let filter = `${this.$apis.termContract}?pageSize=${pageSize}&pageNumber=${pageNumber}&filter=`

      if (this.orderType === 'BARGING_NOMINATION') {
        filter += 'termContractType in (\'CONTRACT_OF_AFFREIGHTMENT\', \'TIME_CHARTER\') and '
      } else {
        filter += `termContractType:'${this.orderType}' and `
      }

      if (this.isLinkedSalesTermContract) {
        if (this.linkedPurchaseTermContract) {
          filter += `'${new Date(this.linkedPurchaseTermContract.contractEndDate).toISOString()}' > contractStartDate and '${new Date(this.linkedPurchaseTermContract.contractStartDate).toISOString()}' < contractEndDate and `
        }
        if (this.linkedSalesTermContract && this.propsView === 'EDIT_ORDER') {
          filter += `sellerSysOrganizationId:'${this.myCompanyId}' and buyerSysOrganizationId:'${this.linkedSalesTermContract.buyerSysOrganizationId}'`
        } else if (this.propsView === 'EDIT_ORDER') {
          filter += `sellerSysOrganizationId:'${this.myCompanyId}' and buyerSysOrganizationId:'${this.buyerSysOrganizationId}'`
        } else {
          filter += `sellerSysOrganizationId:'${this.myCompanyId}'`
        }
      } else {
        if (this.linkedSalesTermContract) {
          filter += `'${new Date(this.linkedSalesTermContract.contractEndDate).toISOString()}' > contractStartDate and '${new Date(this.linkedSalesTermContract.contractStartDate).toISOString()}' < contractEndDate and `
        }
        if (this.linkedPurchaseTermContract && this.propsView === 'EDIT_ORDER') {
          filter += `buyerSysOrganizationId:'${this.myCompanyId}' and sellerSysOrganizationId:'${this.linkedPurchaseTermContract.sellerSysOrganizationId}'`
        } else if (this.propsView === 'EDIT_ORDER') {
          filter += `buyerSysOrganizationId:'${this.myCompanyId}' and sellerSysOrganizationId:'${this.sellerSysOrganizationId}'`
        } else {
          filter += `buyerSysOrganizationId:'${this.myCompanyId}'`
        }
      }

      return filter
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
</style>
